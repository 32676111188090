.wm-form-control {
  display: inline-block;
  padding: $input-btn-padding-y $input-btn-padding-x;
  font-size: $font-size-base;
  line-height: $input-btn-line-height;
  color: $input-color;
  background-color: $input-bg;
  // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214.
  background-image: none;
  background-clip: padding-box;
  border: $input-btn-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @if $enable-rounded {
    // Manually use the if/else instead of the mixin to account for iOS override
    border-radius: $input-border-radius;
  } @else {
    // Otherwise undo the iOS default
    border-radius: 0;
  }

  // @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus();

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}


select.wm-form-control {
  &:not([size]):not([multiple]) {
    height: $input-height;
  }

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}


.wm-form-control--block {
  display: block;
  width: 100%;
}

.wm-form-control + .wm-btn,
.wm-btn + .wm-form-control {

}




// Inline forms
//
// Make forms appear inline(-block) by adding the `.wm-form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.wm-form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.wm-form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  // Because we use flex, the initial sizing of checkboxes is collapsed and
  // doesn't occupy the full-width (which is what we want for xs grid tier),
  // so we force that here.
  .wm-form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .wm-form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    // Allow folks to *not* use `.wm-form-group`
    .wm-form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.wm-form-group`
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .wm-form-control-plaintext {
      display: inline-block;
    }

    .wm-input-group {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .wm-form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
    }
    .wm-form-check-label {
      padding-left: 0;
    }
    .wm-form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }

    // Custom form controls
    .wm-custom-control {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
    }
    .wm-custom-control-indicator {
      position: static;
      display: inline-block;
      margin-right: $form-check-input-margin-x; // Flexbox alignment means we lose our HTML space here, so we compensate.
      vertical-align: text-bottom;
    }

    // Re-override the feedback icon.
    .wm-has-feedback .wm-form-control-feedback {
      top: 0;
    }
  }
}
