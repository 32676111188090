@import 'bootstrap-core';


.wm-btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $input-btn-border-width solid transparent;
  text-transform: uppercase;
  @include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $input-btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  // Share hover and focus styles
  @include hover-focus {
    text-decoration: none;
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $input-btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: .65;
    @include box-shadow(none);
  }

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    background-image: none;
    @include box-shadow($input-btn-focus-box-shadow, $btn-active-box-shadow);
  }
}


//
// Button styles
//

.wm-btn--primary {
  @include button-variant($pm-green-2, $pm-green-2, $pm-green-1, $pm-green-1, $pm-green-1);
}


.wm-btn--secondary {
  @include button-outline-variant($pm-green-2, $pm-green-1);

  @include hover {
    background-color: transparent;
  }
}


//
// Button sizes
//

.wm-btn--small {
  @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $input-btn-line-height-sm, $btn-border-radius-sm);
}


.wm-btn--large {
  @include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $input-btn-line-height-lg, $btn-border-radius-lg);
}


//
// Block button
//

.wm-btn--block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.wm-btn--block + .wm-btn--block {
  margin-top: $btn-block-spacing-y;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.wm-btn--block {
    width: 100%;
  }
}
