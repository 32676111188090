//
// Product Card Module - Mobile
//
// Global styles for product cards used on search,
// category, product recommendations, etc.
// ========================================================

@import '../../resources/styles/style-framework/bootstrap-core';
@import '../../resources/styles/style-framework/buttons';
@import '../../resources/styles/style-framework/forms';
@import '../../resources/styles/utility';
@import 'variables';


//
// Legacy style overrides
// ==========================

.product-card .wm-btn {
  @include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $input-btn-line-height, $btn-border-radius);
  border: 1px solid transparent;
}

//
// Card styles
// ==========================

.product-card {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $gutter-mobile;
  border-bottom: 1px solid $content-border;

  // Language-specific font overrides
  .ja-JP & {
    font-family: '游ゴシック Medium', 'Yu Gothic', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic Pro W3', 'Meiryo UI', 'MS Pゴシック', 'MS PGothic', sans-serif;
  }

  .ko-KR & {
    font-family: 나눔고딕, 'Nanum Gothic', 돋움, Dotum, 'Apple SD Gothic Neo', Helvetica, sans-serif;
  }

  .zh-CN & {
    font-family: 'Microsoft YaHei', 'PingFang SC', Simsun, MHei, 'Lucida Grande', 'Lucida Sans Unicode', 'STHeiti', Arial, sans-serif;
  }

  .zh-HK &,
  .zh-MY &,
  .zh-TW &,
  .zh-SG & {
    font-family: 思源黑體, 'Noto Sans SC', 黑體, SimHei, 微軟雅黑, 'Microsoft YaHei', Arial, sans-serif;
  }
}

//
// Stamp

.product-card__stamp {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 18px;
  font-size: 11px;
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &--active {
    background-color: #666;
  }
}

.product-card__img-link {
  flex: 1 1 percentage(4 / 12);
  margin-right: $gutter-mobile;
}

.product-card__img {
  max-width: 100%;
}

.product-card__details {
  flex: 1 1 percentage(8 / 12);
}

// Title
.product-card__title-link {
  display: block;
  margin-bottom: 8px;
}

.product-card__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: $title-color;
}

.product-card__availability {
  min-height: 32px;
  margin-top: 8px;
}

.product-card__off-sale {
  display: inline-block;
  padding: 8.5px 14px;
  font-size: 14px;
  font-weight: $font-medium;
  color: #fff;
  background-color: $content-lightest;
  border-radius: 3px;
}

.product-card__quantity {
  text-align: center;
  margin-right: 12px;
}

.product-card__meta {
  font-size: 12px;
  line-height: 1.4;

  > dt,
  > dd {
    display: inline-block;
  }
}

.product-card__meta--price {
  font-size: 20px;
}

.product-card__meta--muted {
  color: $content-light;
}

.product-card__meta--savings {
  color: $content-savings
}

